import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Loader = (props) => {
    if (props.visible)
        // if(true)
        return (
            <div style={{ width: '100%', height: "100%", alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff88', zIndex: 1999, top: 0, left: 0, display: 'flex', position: 'absolute' }}>
                <div className="circle_b">
                    <CircularProgressbar
                        value={props?.progress}
                        strokeWidth={10}
                        text={`${Math.trunc(props?.progress)}%`}
                        styles={buildStyles({
                            rotation: 0.50,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `#1b005e`,
                            textColor: '#1b005e',
                            trailColor: '#f1f1f1',
                            backgroundColor: '#1b005e',
                        })}
                    />
                </div>
            </div>
        )
    else
        return null;
}

export default Loader;
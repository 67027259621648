import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
// import './Card.scss';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, path } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userType, setUserType] = useState('users');
  const [canAdd, setCanAdd] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (user?.role == 'Sub') {
      if (user?.permissions[10][Object.keys(user?.permissions[10])[0]].write)
        setCanAdd(true);
      if (user?.permissions[10][Object.keys(user?.permissions[10])[0]].update)
        setCanUpdate(true);
      if (user?.permissions[10][Object.keys(user?.permissions[10])[0]].delete)
        setCanDelete(true);
    } else if (user?.role == 'Admin') {
      setCanAdd(true);
      setCanUpdate(true);
      setCanDelete(true);
    }
  }, [user]);

  useEffect(() => {
    console.log('props on user table', props)
    if (user && userType != "") {
      getData();
    }
  }, [user, userType]);

  const getData = () => {
    let url = "/user/list?type=" + userType;
    get(url, { token: user?.token })
      .then(json => {
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    if (userType == 'queried') {
      setColumns([
        {
          name: 'Email',
          selector: row => row?.email,
        },
        {
          name: 'Created At',
          selector: row => row.createdAt,
        },
        {
          cell: (row) => <>
            {canDelete &&
              <>
                <Button onClick={() => handleDeleteProj(row, 'query')}
                  title={"Ban"}
                  style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-cancel" style={{ fontSize: 20 }}></span></Button>
              </>
            }
          </>,
          name: 'Action',
          ignoreRowClick: true,
        }
      ])
    } else if (userType != "") {
      setColumns([
        {
          name: 'Name',
          selector: row => row?.name,
        },
        {
          name: 'Email',
          selector: row => row?.email,
        },
        {
          name: 'Status',
          selector: row => row?.isEmailVerified ? 'Verified' : 'Pending',
        },
        {
          name: 'Created At',
          selector: row => row.createdAt,
        },
        {
          cell: (row) => <>
            {/* <Button onClick={() => props?.history.push(`/user/detail/` + row?._id, { state: { type: path } })}
            title={"View in details"}
            style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button> */}
            {canDelete &&
              <>
                {/* <Button onClick={() => handleUpdateProj(row)}
                title={"Edit"}
                style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button> */}
                <Button onClick={() => handleDeleteProj(row, 'user')}
                  title={"Ban"}
                  style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-cancel" style={{ fontSize: 20 }}></span></Button>
              </>
            }
          </>,
          name: 'Action',
          ignoreRowClick: true,
          // allowOverflow: true,
          // button: true,
        },
      ]);
    }
  }, [data, userType]);

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj, type) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete', type: type });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      userId: currentData?._id,
      token: user?.token
    }
    let url = "/user/delete";
    if (currentData?.type == 'query') {
      body = { ...body, queryId: currentData?._id };
    }
    post(url, body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getData();
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Col className="col-12">
            <Card>
              <CardBody>
                {from != 'Dashboard' &&
                  <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                    <div>
                      <CardTitle className="h4">{props.role}</CardTitle>
                      <CardSubtitle className="mb-3">
                        {role} those who registered himself, All the <code>Users</code> will also be able see and <code>actions</code> on the <code>Properties.</code>
                      </CardSubtitle>
                    </div>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <AvForm>
                        <AvField
                          name="userType"
                          label="Filters"
                          type="select"
                          value={userType}
                          onChange={(e) => setUserType(e.target.value)}
                        >
                          <option value="">Select Filter</option>
                          <option value="users">Registered</option>
                          <option value="verified">Verified</option>
                          <option value="pending">Pending</option>
                          <option value="queried">Queried</option>
                        </AvField>
                      </AvForm>
                    </div>
                  </div>
                }
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
export default Table;

import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Utils from '../Utility'

import avatar from "../../assets/images/companies/img-4.png"
// actions
import { createCompany, companySuccess, companyError, updateCompany } from "../../store/actions";
import { profileSuccess, getProfile } from '../../store/auth/profile/actions';
import AddNotiForm from '../Forms/AddNotiForm';
import Projects from './Card';
import Loader from "../../components/Loader";

const Project = props => {
  const { user, company } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [currentNoti, setCurrentNoti] = useState(null);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  // console.log('props on company', props);


  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Notification" />

        <Row>
          <Col lg="12">
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}
            {props.success && props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null}
          </Col>
        </Row>
        {isAddForm ?
          <AddNotiForm
            role={"Notification"}
            user={user}
            data={currentNoti}
            close={setIsAddForm}
            loading={loading}
            setLoading={setLoading}
            setCurrentNoti={setCurrentNoti}
          />
          : <Card>
            <CardBody>
              <Projects
                role={"Project"}
                user={props?.user}
                add={setIsAddForm}
                currentNoti={currentNoti}
                setCurrentNoti={setCurrentNoti}
                history={props.history}
              />
            </CardBody>
          </Card>
        }

      </div>
    </React.Fragment>
  )
}

Project.propTypes = {
  getProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  companyError: PropTypes.func,
  companySuccess: PropTypes.func,
  createCompany: PropTypes.func,
  updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  const { company } = state.Company;
  return { user, company }
}

export default withRouter(
  connect(mapStatetoProps, { createCompany, companySuccess, companyError, getProfile, updateCompany })(Project)
)

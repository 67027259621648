import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Alert,
  Label,
  Input,
  Button
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { post, put, get } from '../../helpers/api_helper';
import Utils from '../Utility';
import { toast } from "react-toastify";

const AddUserForm = (props) => {
  const { user, data, loading, setLoading } = props;
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [target, setTarget] = useState(data?.role ? data?.role : "");
  const [descLength, setDescLength] = useState(0);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (props?.user) {
      getProjects();
    }
  }, [user]);

  const getProjects = () => {
    if (user?.companyId) {
      get("/project/list?companyId=" + user?.companyId, { token: user?.token })
        .then(json => {
          console.log('response from get user list', json);
          setProjects(json?.data);
        })
        .catch(error => {
          console.log('error while getting user list', error);
        });
    } else {
      setErrorMsg("Ask your Admin to create a project first!");
    }
  }

  function handleValidSubmit(event, values) {
    console.log('values from form', values, data);
    if (user?.companyId) {
      if (data) {
        //updating user
        const body = {
          ...values,
          token: user?.token,
          notiId: data?._id
        };
        setLoading(true);
        let url = "/notification/update";
        put(url, body)
          .then((json) => {
            console.log('response from updating noti', json);
            setLoading(false);
            if (json.statusCode == 200) {
              setSuccessMsg(json?.message);
              setErrorMsg(null);
              props.close(false);
            } else {
              setErrorMsg(json?.error);
              setSuccessMsg(null);
            }
          })
          .catch(error => {
            console.error('error while adding user', error);
            setErrorMsg('Something Went Wrong!');
            setSuccessMsg(null);
            setLoading(false);
          })
      } else {
        //adding new user
        const body = {
          ...values,
          addedBy: user?._id,
          token: user?.token,
          companyId: user?.companyId
        };
        let url = "/notification/add";
        setLoading(true);
        post(url, body)
          .then((json) => {
            console.log('response from adding user', json);
            setLoading(false);
            if (json.statusCode == 200) {
              toast.success(json?.message);
              setSuccessMsg(json?.message);
              setErrorMsg(null);
              props.close(false);
            } else {
              setErrorMsg(json?.error);
              setSuccessMsg(null);
            }
          })
          .catch(error => {
            console.error('error while adding user', error);
            setErrorMsg('Something Went Wrong!');
            setSuccessMsg(null);
            setLoading(false);
          })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col>
            {errorMsg &&
              <Alert color="danger">{errorMsg}</Alert>
            }
            {successMsg &&
              <Alert color="success">{successMsg}</Alert>
            }
            <Card>
              <CardBody>
                <div className="">
                  <div className="d-flex" style={{ marginLeft: 'auto' }}>
                    <div>
                      <CardTitle className="h4">{data ? 'Update' : 'Add New'} Notification</CardTitle>
                      <p className="card-title-desc">
                        This <code>Notification</code> Will be just saved not send, For sending to target you need to click on <code>send button.</code>
                      </p>
                    </div>
                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                      <Button color="primary" onClick={() => { props.setCurrentNoti(null); props?.close(false) }} style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                        Close
                      </Button>
                    </div>
                  </div>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <div className="form-group mb-4">
                      <AvField
                        name="role"
                        label={"Notification Target"}
                        value={target}
                        className="form-control"
                        placeholder={`Select Notification Target`}
                        type="select"
                        required
                        onChange={e => setTarget(e.target.value)}
                      >
                        <option value="">Select Target</option>
                        <option value="Company">All</option>
                        <option value="Project">Project</option>
                      </AvField>
                    </div>

                    {target == 'Project' &&
                      <div className="form-group mb-4">
                        <AvField
                          name="projectId"
                          label={"Select Project"}
                          value={data?.projectId}
                          className="form-control"
                          placeholder={`Select Project`}
                          type="select"
                          required
                        >
                          <option value="">Select Project</option>
                          {projects.map(item => (
                            <option value={item?._id} key={item?._id} >{item.name}</option>
                          ))}
                        </AvField>
                      </div>
                    }

                    <div className="form-group mb-4">
                      <AvField
                        name="title"
                        label={"Notification Title"}
                        value={data?.title}
                        className="form-control"
                        placeholder={`Enter Title`}
                        type="text"
                        required
                      />
                    </div>
                    <div className="form-group mb-4">
                      <AvField
                        name="body"
                        label={"Notification Description"}
                        value={data?.body}
                        className="form-control"
                        placeholder={`Enter Description`}
                        type="text"
                        required
                        validate={{ maxLength: { value: 200 } }}
                        onChange={(t) => setDescLength(t.target.value.length)}
                      />
                    </div>
                    <div className="mt-3" style={{ textAlign: 'end' }}>
                      <p>{descLength}/200</p>
                    </div>
                    <div className="form-group mb-4">
                      <AvField
                        name="image"
                        label={"Notification Image"}
                        value={data?.image}
                        className="form-control"
                        placeholder={`Upload Notification Image`}
                        type="file"
                      // required
                      />
                    </div>

                    {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                    <div className="text-center mt-4">
                      {data ?
                        <Button type="submit" color="primary" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                          Update Notification
                        </Button>
                        :
                        <Button type="submit" color="primary" style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor }}>
                          Add Notification
                        </Button>
                      }
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default AddUserForm;

import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postJwtProfileUpdate,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"

function* editProfile({ payload: { user } }) {
  try {
      const response = yield call(postJwtProfileUpdate, {
        userId: user._id,
        token:user?.token
      })
      if (response.statusCode == 200)
      console.log('profile updated', response);
        // yield put(profileSuccess(response))
      else
        throw response.message;
  } catch (error) {
    yield put(profileError(error))
  }
}

function* getProfile({ payload: { user } }) {
  try {
    // console.log('user on get profile saga', user);
    if (user) {
      console.log('calling get profile')
      // console.log('req, of get profile', JSON.stringify({
      //   user_id: user?._id,
      //   token: user?.token
      // }));
      const response = yield call(postJwtProfile, {
        userId: user?._id,
        token: user?.token
      });
      // console.log('response from get profile', response?.user);
      if (response.statusCode == 200){
          response.user.token =  user?.token;
        localStorage.setItem("authUser", JSON.stringify({...response?.user, token:user?.token}));
        yield put(profileSuccess({...response?.user, token:user?.token}));
      }else
        throw response.message;
    }
  } catch (error) {
    console.error('error while getting profile', error);
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getProfile)
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga

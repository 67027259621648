import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//page
import Country from '../pages/MetaData/Country';
import Continent from '../pages/MetaData/Continent';
import Notification from "../pages/Notification";
import Month from "../pages/MetaData/Month";
import Experience from '../pages/MetaData/Experience';
import Who from "../pages/MetaData/Who";
import Inspiration from "../pages/MetaData/Inspiration";
import Location from "../pages/MetaData/Location";
import Property from "../pages/Property";
import Hotel from "../pages/Hotel";
import Ads from "../pages/Ads";
import Users from "../pages/Users";
import ImageStore from "../pages/ImageStore";
import Category from "../pages/Blog/category";
import Author from "../pages/Blog/author";
import Blog from "../pages/Blog";
import Video from "../pages/Blog/Video";
import Sitemap from "../pages/Sitemap";
import Admin from "../pages/Admin";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // { path: '/dashboard2', component: Dashboard2 },
  { path: "/country", component: Country },
  { path: "/continent", component: Continent },
  { path: "/month", component: Month },
  { path: "/experience", component: Experience },
  { path: "/who", component: Who },
  { path: "/inspiration", component: Inspiration },
  { path: "/location", component: Location },
  { path: "/property", component: Property },
  { path: "/property/:id", component: Property },
  { path: "/hotel", component: Hotel },
  { path: "/ads", component: Ads },
  { path: "/users", component: Users },
  { path: "/admin", component: Admin },
  { path: "/store", component: ImageStore },
  { path: '/category', component: Category },
  { path: '/author', component: Author },
  { path: '/blog', component: Blog },
  { path: '/video', component: Video },
  { path: "/sitemap", component: Sitemap },

  //Notification
  { path: "/notification", component: Notification },
  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }
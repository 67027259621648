import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
} from "reactstrap"

// availity-reactstrap-validation

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Utils from '../../Utility'

import avatar from "../../../assets/images/companies/img-4.png"
// actions
import { profileSuccess, getProfile } from '../../../store/auth/profile/actions';
// import AddCompany from '../Forms/AddCompany';
import Table from './Table';
import Loader from "../../../components/Loader";

const Experience = props => {
  const { user } = props;
  const [isAddForm, setIsAddForm] = useState(false);
  const [isTable, setIsTable] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("bank");
  // console.log('props on company', props);

  return (
    <React.Fragment>
      <div className="page-content">
        <Loader visible={loading} />
        {/* Render Breadcrumb */}
        <Breadcrumb title={Utils.projectName} breadcrumbItem="Who" />
        <Card>
          <CardBody>
            <Table
              role={"Who"}
              path={path}
              user={props?.user}
              history={props.history}
              setLoading={setLoading}
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

Experience.propTypes = {
  getProfile: PropTypes.func,
}

const mapStatetoProps = state => {
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStatetoProps, { getProfile })(Experience)
)

import React, { useEffect, useState, useRef } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, Label } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { toast } from 'react-toastify';
import Utils from '../Utility';
import { post, get, put, upload } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";

// import './Card.scss';

const Table = (props) => {
  const { user, role, currentUser, path, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentModalData, setCurrentModalData] = useState(null);
  const [adType, setAdType] = useState('');
  const [isAddForm, setIsAddForm] = useState(false);
  const [file, setFile] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);
  const imageInput = useRef();
  const cropper = useRef();
  const [canAdd, setCanAdd] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (user?.role == 'Sub') {
      if (user?.permissions[9][Object.keys(user?.permissions[9])[0]].write)
        setCanAdd(true);
      if (user?.permissions[9][Object.keys(user?.permissions[9])[0]].update)
        setCanUpdate(true);
      if (user?.permissions[9][Object.keys(user?.permissions[9])[0]].delete)
        setCanDelete(true);
    } else if (user?.role == 'Admin') {
      setCanAdd(true);
      setCanUpdate(true);
      setCanDelete(true);
    }
  }, [user]);


  useEffect(() => {
    if (user) {
      getMetas();
    }
  }, [user, path]);

  const getMetas = () => {
    get("/ads/list")
      .then(json => {
        // // console.log('response from get project list', path);
        if (json?.statusCode == 200) {
          setData(json?.data);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        console.log('error while getting project list', error);
      })
  }

  useEffect(() => {
    // console.log(data?.length > 0 && data[0]?.banner);
    setColumns([
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'Image'} src={row?.banner} style={{ width: 150, height: 'auto' }} />
        </>,
      },
      {
        name: 'Type',
        selector: row => row?.type,
      },
      {
        name: 'Screen',
        selector: row => row?.screen,
      },
      {
        name: 'Position',
        selector: row => row?.position,
      },
      {
        name: 'Updated At',
        selector: row => row.updatedAt,
      },
      {
        cell: (row) => <>
          <Button onClick={() => { if (user?.role == 'Admin') handleUpdateStatus(row); else toast.error("You don't have permission for this action!") }}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
      },
      {
        cell: (row) => <>
          {canUpdate &&
            <Button onClick={() => handleUpdateEdit(row, 'bank')}
              title={"Edit"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          }
          {canDelete &&
            <Button onClick={() => handleDelete(row, 'bank')}
              title={"Delete"}
              style={{ backgroundColor: Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data]);

  useEffect(() => {
    if (!isAddForm) {
      setCurrentModalData(null);
      // imageInput.current.value = "";
    }
  }, [isAddForm])

  const handleUpdateEdit = (proj) => {
    console.log('updating proj', proj);
    setCurrentModalData(proj);
    setFile(proj?.banner);
    setAdType(proj?.type);
    setImageChanged(false);
    setIsAddForm(true);
  }

  const handleDelete = (proj, metaType) => {
    console.log('updating proj', proj);
    setCurrentModalData({ ...proj, metaType, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentModalData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentModalData);
    let body = {
      adsId: currentModalData?._id,
      token: user?.token
    }
    post(`/ads/delete`, body)
      .then(json => {
        console.log('response from delete user', json);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const onStatus = () => {
    console.log('status', currentModalData);
    setLoading(true);
    let body = {
      adsId: currentModalData?._id,
      isActive: !currentModalData?.isActive,
      token: user?.token
    }
    let url = `/ads/update`;
    put(url, body)
      .then(json => {
        console.log('response from delete user', json);
        setLoading(false);
        if (json.statusCode == 200) {
          toast.success(json?.message);
          getMetas();
          setDeleteModal(false);
          setCurrentModalData(null);
        } else {
          toast.error(json?.error);
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
        console.error('error while deleting user', error);
      })
  }

  const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1800,
        600,
        "png",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleValidSubmit = async (e, values, type) => {
    // console.log('values', values, file);
    setLoading(true);
    if (currentModalData) {
      let body = {
        ...values,
        adsId: currentModalData?._id,
        token: user?.token
      }
      if (imageChanged) {
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
        console.log('values 2', fileObj);
        const image2 = await resizeFile(fileObj);
        console.log('values 2 after resize', image2);
        const form2 = new FormData();
        form2.append("image", image2);
        const uploadedBanner = await upload("/ads/image_upload", form2)
        console.log('response from image upload', uploadedBanner);
        if (uploadedBanner?.statusCode == 200)
          body.banner = uploadedBanner?.data;
      }
      put("/ads/update", body)
        .then(json => {
          console.log('response from add month', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setImageChanged(false);
            setIsAddForm(false);
            setCurrentModalData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        });
    } else {
      setLoading(true);
      let banner = "";
      const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
      const fileObj = await urltoFile(fileData, (new Date().getTime() + 300) + '.png', 'image/png')
      console.log('values 2', fileObj);
      const image2 = await resizeFile(fileObj);
      console.log('values 2 after resize', image2);
      const form2 = new FormData();
      form2.append("image", image2);
      const uploadedBanner = await upload("/ads/image_upload", form2)
      console.log('response from image upload', uploadedBanner);
      if (uploadedBanner?.statusCode == 200)
        banner = uploadedBanner?.data;
      const body = {
        ...values,
        banner: banner,
        token: user?.token
      }
      post("/ads/add", body)
        .then(json => {
          console.log('response from add ads', json);
          setLoading(false);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getMetas();
            setFile(null);
            setImageChanged(false);
            setIsAddForm(false);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          console.log('error while uploading image', error);
          toast.error(error);
        })
    }
  }

  const onChangeFile = (e) => {
    console.log('getting event on input img', e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
    setImageChanged(true);
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => currentModalData?.actionType == 'Status' ? onStatus() : onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            actionType={currentModalData?.actionType}
            data={currentModalData}
          />
          {/* Add or Update depart form */}
          <Modal isOpen={isAddForm} toggle={() => { setIsAddForm(false); setFile(null) }} centered={true}>
            <ModalBody className="py-3 px-5">
              <h5 className="text-black font-size-20">{currentModalData ? 'Update ads!' : 'Add New ads!'}</h5>
              <div className="p-2">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v, 'bank')
                  }}
                >
                  <div className="mb-3">
                    <AvField
                      name="type"
                      label="Type"
                      className="form-control"
                      type="select"
                      required
                      value={currentModalData?.type}
                      onChange={(e) => setAdType(e.target.value)}
                    >
                      <option value="">Select Ad type</option>
                      <option value="image">Image</option>
                      <option value="iframe">IFrame</option>
                    </AvField>
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="screen"
                      label="Screen"
                      className="form-control"
                      type="select"
                      required
                      value={currentModalData?.screen}
                    >
                      <option value="">Select Screen</option>
                      <option value="home">Home</option>
                      <option value="inner">Inner</option>
                      <option value="detail">Detail</option>
                    </AvField>
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="position"
                      label="Position"
                      className="form-control"
                      type="select"
                      required
                      value={currentModalData?.position}
                    >
                      <option value="">Select Position</option>
                      <option value="top">Top</option>
                      <option value="bottom">Bottom</option>
                    </AvField>
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="utm"
                      label="Booking UTM"
                      className="form-control"
                      placeholder="Enter Booking UTM URL"
                      type="url"
                      value={currentModalData?.utm}
                      required
                    />
                  </div>
                  {adType == 'image' &&
                    <div className="mb-3 mt-2">
                      {file ?
                        <>
                          <CardTitle >Image</CardTitle>
                          <div style={{ flexDirection: 'row', position: 'relative', cursor: 'pointer', marginTop: 20 }}>
                            {currentModalData?.banner ?
                              <img src={file} style={{ width: 400, height: 'auto' }} />
                              :
                              <Cropper
                                style={{ height: 'auto', width: 400 }}
                                // aspectRatio={16 / 5}
                                preview=".img-preview"
                                guides={true}
                                src={file}
                                ref={cropper}
                              />
                            }
                            <i className="mdi mdi-close" style={{
                              color: 'white', backgroundColor: Utils.themeColor, fontSize: 20,
                              position: 'absolute', top: -16, right: -28, borderRadius: 15, width: 30, height: 30, textAlign: 'center'
                            }}
                              onClick={() => { setFile(null); if (currentModalData) setCurrentModalData({ ...currentModalData, banner: undefined }) }}
                            />
                          </div>
                        </>
                        :
                        <AvField
                          name="fileInput"
                          label="Image"
                          className="form-control"
                          placeholder="upload bank logo"
                          type="file"
                          onChange={e => onChangeFile(e)}
                          required
                          multiple={false}
                          ref={imageInput}
                          accept="image/png, image/jpeg"
                        // value={currentModalData?.logo}
                        />
                      }
                    </div>
                  }
                  <div className="mb-3">
                    <AvField
                      name="desc"
                      label="Overview"
                      className="form-control"
                      placeholder="Enter overview"
                      type="textarea"
                      row={2}
                      value={currentModalData?.desc}
                      required
                    />
                  </div>
                  <div className="mt-4">
                    <Row>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          style={{ backgroundColor: Utils.themeColor }}
                          onClick={() => { setIsAddForm(false); setFile(null) }}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </Col>
                      <Col md={6}>
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          style={{ backgroundColor: Utils.themeColor }}
                        >
                          {currentModalData ? 'Update' : 'Add'}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </AvForm>
              </div>
            </ModalBody>
          </Modal>

          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code>Ads </code> will be shown to distinct pages as per their type.
                    </CardSubtitle>
                  </div>
                </div>

                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <h5>Ads Management</h5>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {canAdd &&
                      <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => setIsAddForm(true)}>
                        Add
                      </Button>
                    }
                  </div>
                </div>

                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        maxWidth: 80
                      },
                    },
                    cells: {
                      style: {
                        maxWidth: 50
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment >
  )
}

export default Table;

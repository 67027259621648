import React, { useState, useEffect } from 'react';
import Utils from '../Utility';
import Loader from '../../components/Loader';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { Card, CardBody } from 'reactstrap';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { get } from '../../helpers/api_helper';
import { toast } from 'react-toastify';


const Sitemap = (props) => {
    const { user } = props;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (user?.token) {
            getSiteMap();
        }
    }, [user]);

    const getSiteMap = () => {
        get("/property/generate_sitemap", { token: user?.token })
            .then(res => {
                if (res?.statusCode == 200) {
                    setData(res?.data);
                } else {
                    toast.error(res?.error);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error('Something Went Wrong!');
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Loader visible={loading} />
                <Breadcrumb title={Utils.projectName} breadcrumbItem="Sitemap generator" />
                <Card>
                    <CardBody>
                        <div className='d-flex justify-content-between'>
                            <h5>List of all available Urls</h5>
                            <h5>Total Urls - {data?.length}</h5>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            {data?.map((item) => {
                                return (
                                    <div className='d-flex justify-content-between'>
                                        <span>{item?.loc}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(Sitemap));
import PropTypes from "prop-types"
import React, { useEffect, useState, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom";
import Utils from '../../pages/Utility';
import { get } from "../../helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { connect } from "react-redux";
import { getNotiSuccess } from "../../store/actions";

const SidebarContent = props => {
  // console.log('props on slidebar content', props);
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [permission, setPermissions] = useState([]);
  const [isMeta, setIsMeta] = useState(false);

  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    if (pathName) {
      const initMenu = () => {
        new MetisMenu("#side-menu")
        let matchingMenuItem = null
        const ul = document.getElementById("side-menu")
        const items = ul.getElementsByTagName("a")
        for (let i = 0; i < items.length; ++i) {
          if (pathName === items[i].pathname) {
            matchingMenuItem = items[i]
            break
          }
        }
        // console.log('matching pathName', matchingMenuItem, typeof matchingMenuItem);
        if (matchingMenuItem) {
          activateParentDropdown(matchingMenuItem)
        }
      }
      initMenu()
    }
  }, [props.location.pathname, props?.user?.role, isSubAdmin, isMeta])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    item.style.color = Utils.themeColor;
    item.style.fontWeight = 'bold';
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  useEffect(() => {
    if (props?.user?.role == "Sub") {
      setIsSubAdmin(true);
      // console.log('permissions of user', props?.user?.permissions);
      setPermissions(props?.user?.permissions);
      props?.user?.permissions?.map((item, index) => {
        // console.log('permissions of user', item);
        if (index < 5) {
          if (item[Object.keys(item)[0]]?.read)
            setIsMeta(true);
        }
      })
    }
  }, [props?.user])

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-airplay" style={{ color: Utils.themeColor }}></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {props.user?.role == 'Admin' &&
              <>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-database-import" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Meta-Data")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/continent">{props.t("Continent")}</Link></li>
                    <li><Link to="/country">{props.t("Country")}</Link></li>
                    <li><Link to="/month">{props.t("Month")}</Link></li>
                    <li><Link to="/experience">{props.t("Experience")}</Link></li>
                    <li><Link to="/who">{props.t("Who")}</Link></li>
                    {/* <li><Link to="/inspiration">{props.t("Inspiration")}</Link></li> */}
                    {/* <li><Link to="/location">{props.t("Location")}</Link></li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/store" className="waves-effect">
                    <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Image Store")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/property" className="waves-effect">
                    <i className="mdi mdi-cards-variant" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Properties")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/hotel" className="waves-effect">
                    <i className="mdi mdi-office-building" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Hotels")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/blog" className="waves-effect">
                    <i className="mdi mdi-post-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Blogs")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"}>
                    <i className="mdi mdi-post-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Blogs")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/category">{props.t("Category")}</Link></li>
                    <li><Link to="/author">{props.t("Author")}</Link></li>
                    <li><Link to="/blog">{props.t("Blogs")}</Link></li>
                    <li><Link to="/video">{props.t("Video")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/ads" className="waves-effect">
                    <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Ads Management")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/users" className="waves-effect">
                    <i className="mdi mdi-account-multiple" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("User Management")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin" className="waves-effect">
                    <i className="mdi mdi-account-key" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Sub Admin")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/sitemap" className="waves-effect">
                    <i className="mdi mdi-map" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Sitemap")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"} onClick={() => setSubMenu(!subMenu)}>
                    <i className="mdi mdi-account-circle-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("Agents")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/users/unverified">{props.t("Unverified Agents")}</Link></li>
                    <li><Link to="/users/verified">{props.t("Verified Agents")}</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to={"/#"} className={"has-arrow waves-effect"} onClick={() => setSubMenu(!subMenu)}>
                    <i className="mdi mdi-account-circle-outline" style={{ color: Utils.themeColor }}></i>
                    <span>{props.t("App Management")}</span>
                  </Link>
                  <ul className={"sub-menu"}>
                    <li><Link to="/app/hero_banner">{props.t("Hero Banner")}</Link></li>
                    <li><Link to="/app/banner_1">{props.t("Banner 1")}</Link></li>
                    <li><Link to="/app/banner_2">{props.t("Banner 2")}</Link></li>
                  </ul>
                </li> */}
              </>
            }
            {isSubAdmin &&
              <>
                {isMeta &&
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-database-import" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Meta-Data")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      {permission[0][Object.keys(permission[0])[0]].read &&
                        <li><Link to="/continent">{props.t("Continent")}</Link></li>
                      }
                      {permission[1][Object.keys(permission[1])[0]].read &&
                        <li><Link to="/country">{props.t("Country")}</Link></li>
                      }
                      {permission[2][Object.keys(permission[2])[0]].read &&
                        <li><Link to="/month">{props.t("Month")}</Link></li>
                      }
                      {permission[3][Object.keys(permission[3])[0]].read &&
                        <li><Link to="/experience">{props.t("Experience")}</Link></li>
                      }
                      {permission[4][Object.keys(permission[4])[0]].read &&
                        <li><Link to="/who">{props.t("Who")}</Link></li>
                      }
                    </ul>
                  </li>
                }
                {permission[5][Object.keys(permission[5])[0]].read &&
                  <li>
                    <Link to="/store" className="waves-effect">
                      <i className="mdi mdi-image-multiple" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Image Store")}</span>
                    </Link>
                  </li>
                }
                {permission[6][Object.keys(permission[6])[0]].read &&
                  <li>
                    <Link to="/property" className="waves-effect">
                      <i className="mdi mdi-cards-variant" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Properties")}</span>
                    </Link>
                  </li>
                }
                {permission[7][Object.keys(permission[7])[0]].read &&
                  <li>
                    <Link to="/hotel" className="waves-effect">
                      <i className="mdi mdi-office-building" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Hotels")}</span>
                    </Link>
                  </li>
                }
                {permission[8][Object.keys(permission[8])[0]].read &&
                  <li>
                    <Link to={"/#"} className={"has-arrow waves-effect"}>
                      <i className="mdi mdi-post-outline" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Blogs")}</span>
                    </Link>
                    <ul className={"sub-menu"}>
                      {permission[8][Object.keys(permission[8])[0]].write || permission[8][Object.keys(permission[8])[0]].update ?
                        <>
                          <li><Link to="/category">{props.t("Category")}</Link></li>
                          <li><Link to="/author">{props.t("Author")}</Link></li>
                        </>
                        : <></>
                      }
                      <li><Link to="/blog">{props.t("Blogs")}</Link></li>
                    </ul>
                  </li>
                }
                {permission[9][Object.keys(permission[9])[0]].read &&
                  <li>
                    <Link to="/ads" className="waves-effect">
                      <i className="mdi mdi-post" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("Ads Management")}</span>
                    </Link>
                  </li>
                }
                {permission[10][Object.keys(permission[10])[0]].read &&
                  <li>
                    <Link to="/users" className="waves-effect">
                      <i className="mdi mdi-account-multiple" style={{ color: Utils.themeColor }}></i>
                      <span>{props.t("User Management")}</span>
                    </Link>
                  </li>
                }
              </>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  )
}

const mapStateToProps = state => {
  const { notis } = state.notification;
  return { notis }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  getNotiSuccess: PropTypes.func,
}

export default withRouter(withTranslation()(connect(mapStateToProps, { getNotiSuccess })(SidebarContent)))
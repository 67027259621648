import PropTypes from "prop-types";
import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Progress } from "reactstrap"
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { getProfile } from '../../store/auth/profile/actions';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Components
import LineChart from "./line-chart";
import RevenueChart from "./revenue-chart";
import SalesAnalytics from "./sales-analytics";
import LatestTransaction from "./latest-transaction";

//Import Image
import Revenue from './Revenue';
import Inbox from './Inbox';
import { get } from "../../helpers/api_helper";
import Utils from "../Utility";

const Dashboard = (props) => {
  const { user } = props;
  const [data, setData] = useState({ source: [], dealers: [], moderator: [] });
  const [time, setTime] = useState(1);

  useEffect(() => {
    // console.log('props on home ', user);
    if (user) {
      // getStats();
    }
  }, [user, time]);

  const getStats = () => {
    // console.log('calling get stat');
    get("/property/stat?days=" + time, { token: user?.token })
      .then(json => {
        // console.log('response from get stat', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
        }
      })
      .catch(error => {
        console.error('Error while getting stat', error);
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to {Utils.projectName} Dashboard</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>
        {/* <Row style={{ justifyContent: 'right' }}>
          <Col lg={2}>
            <div style={{ width: '100%' }}>
              <AvForm stye={{ float: 'right' }}>
                <AvField
                  type="select"
                  name="time"
                  label={"Time"}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                >
                  <option value={1}>Today</option>
                  <option value={2}>Yesterday</option>
                  <option value={7}>Last 7 Days</option>
                  <option value={15}>Last 15 Days</option>
                  <option value={30}>Last 30 Days</option>
                  <option value={180}>Last 6 Month</option>
                  <option value={365}>Last 1 Year</option>
                </AvField>
              </AvForm>
            </div>
          </Col>
        </Row> */}
        {/* <Row className="mt-2">
          <Col lg={3}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-thumb-up"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Total Likes</div>
                  </div>
                </div>
                <h4 className="mt-4">{data?.likes}</h4>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start">
                  <div className="avatar-sm font-size-20 me-3">
                    <span className="avatar-title bg-soft-primary text-primary rounded">
                      <i className="mdi mdi-account-multiple-outline"></i>
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="font-size-16 mt-2">Users</div>

                  </div>
                </div>
                <h4 className="mt-4">{data?.user?.length}</h4>
                <Row>
                  <div className="col-7">
                    <p className="mb-0"><span className="text-success me-2"> 0.16% <i
                      className="mdi mdi-arrow-up"></i> </span></p>
                  </div>
                  <div className="col-5 align-self-center">
                    <Progress
                      value="62"
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={9}>
            <LineChart data={data?.reviews} time={time} />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Card className="bg-primary" style={{ height: 342 }}>
              <CardBody>
                <div className="text-white">
                  <h5 className="text-white">2400 + New Users</h5>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus</p>
                  <div>
                    <Link to="#" className="btn btn-outline-success btn-sm">View more</Link>
                  </div>
                </div>
                <Row className="justify-content-end">
                  <div className="col-8">
                    <div className="mt-4">
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
          </Col>
          <Col lg={4}>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
          </Col>
          <Revenue />
        </Row>
        <Row>
          <LatestTransaction user={user} history={props?.history} />
        </Row> */}

      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  getProfile: PropTypes.func
}

const mapStateToProps = state => {
  // console.log('state at dashboard', state);
  const { user } = state.Profile;
  return { user }
}

export default withRouter(
  connect(mapStateToProps, { getProfile })(Dashboard)
)
import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody } from "reactstrap"
// import "./datatables.scss";
// import '../Icons/IconFontawesome';

import Utils from '../Utility';
import { post, get, put, download } from "../../helpers/api_helper";
import DeleteModal from './DeleteModal';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { saveAs } from 'file-saver';
// import './property.scss';

const Table = (props) => {
  const { user, role, currentData, from, setCurrentData, setLoading } = props;
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const [reviewData, setReviewData] = useState([{ desc: "" }, { desc: "" }, { desc: "" }, { desc: "" }, { desc: "" }]);
  const [canAdd, setCanAdd] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [newPerPage, setNewPerPage] = useState(50);

  useEffect(() => {
    if (user?.role == 'Sub') {
      if (user?.permissions[6][Object.keys(user?.permissions[6])[0]].write)
        setCanAdd(true);
      if (user?.permissions[6][Object.keys(user?.permissions[6])[0]].update)
        setCanUpdate(true);
      if (user?.permissions[6][Object.keys(user?.permissions[6])[0]].delete)
        setCanDelete(true);
    } else if (user?.role == 'Admin') {
      setCanAdd(true);
      setCanUpdate(true);
      setCanDelete(true);
    }
  }, [user]);


  useEffect(() => {
    if (user) {
      getData(0, 50);
    }
  }, [user]);

  const getData = (currentPage, perPage) => {
    setLoading(true);
    get("/property/pagination?search=" + search + "&currentPage=" + currentPage + "&perPage=" + perPage, { token: user?.token })
      .then(json => {
        setLoading(false);
        // console.log('response from get project list', json);
        if (json?.statusCode == 200) {
          setData(json?.data);
          setTotalPage(json?.totalPage);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log('error while getting project list', error);
      })
  }

  const handleIsPopular = (id, val) => {
    console.log('changing popular', id, val);
    const body = {
      propertyId: id,
      isPopular: val == "true" ? false : true,
      token: user?.token
    }
    put("/property/update", body)
      .then(res => {
        console.log('response from changing popular', res);
        if (res?.statusCode == 200) {
          toast.success(res?.message);
        } else
          toast.error(res?.erorr);
      })
      .catch(error => {
        console.log('error while changing popular', error);
        toast.error(error);
      })
  }

  useEffect(() => {
    setColumns([
      user?.role == "Admin" && {
        cell: (row) => <>
          <AvForm>
            <div className="d-flex align-items-center">
              <AvField
                name="isPopular"
                type="checkbox"
                value={row?.isPopular ? true : false}
                onChange={(e) => handleIsPopular(row?._id, e.target.value)}
              />
              <i className="mdi mdi-pencil" style={{ color: "#fff", height: 15, width: 15, borderRadius: 5, marginTop: -3, cursor: 'pointer', textAlign: 'center', lineHeight: 1.5, marginLeft: 5, backgroundColor: Utils.themeColor, fontSize: 10 }}
                onClick={() => handleAddReview(row)}
              >
              </i>
            </div>
          </AvForm>
        </>,
        name: <i className="mdi mdi-star" style={{ fontSize: 17 }} />,
        ignoreRowClick: true,
        maxWidth: "100px"
      },
      {
        name: 'Image',
        cell: (row) => <>
          <img title={'banner'} src={row?.banner} style={{ width: '100%', height: 'auto', marginTop: 5, marginBottom: 5 }} />
        </>,
        maxWidth: "150px"
      },
      {
        name: 'Title',
        selector: row => row.name,
      },
      {
        name: 'Country',
        selector: row => row?.country[0]?.name,
        maxWidth: "140px"
      },
      {
        name: 'Location',
        selector: row => row?.location,
        maxWidth: "140px"
      },
      {
        cell: (row) => <>
          <Button onClick={() => { if (user?.role == 'Admin') handleUpdateStatus(row); else toast.error("You don't have permission for this action!") }}
            title={row?.isActive ? "Inactive" : "Active"}
            style={{ backgroundColor: !row.isActive ? 'red' : 'green', marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}>
            <span className="text-white" style={{}}>
              {!row?.isActive ? 'Inactive' : 'Active'}
            </span>
          </Button>
        </>,
        name: 'Status',
        ignoreRowClick: true,
        maxWidth: "120px"
      },
      {
        cell: (row) => <>
          {user?.role == 'Admin' &&
            <Button onClick={() => props?.history.push(`/property/` + row?._id)}
              title={"View"}
              style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-eye" style={{ fontSize: 20 }}></span></Button>
          }
          {canUpdate &&
            <Button onClick={() => handleUpdateProj(row)}
              title={"Edit"}
              style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-pencil" style={{ fontSize: 20 }}></span></Button>
          }
          {canDelete &&
            <Button onClick={() => handleDeleteProj(row)}
              title={"Delete"}
              style={{ backgroundColor: user?.company?.theme ? user?.company?.theme : Utils.themeColor, marginRight: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-delete" style={{ fontSize: 20 }}></span></Button>
          }
        </>,
        name: 'Action',
        ignoreRowClick: true,
        maxWidth: "150px"
        // allowOverflow: true,
        // button: true,
      },
    ])
  }, [data, user?.role]);

  const handleOnHome = (id, val) => {
    console.log('checking on home', id, val);
    let body = {
      propertyId: id,
      onHome: val == "false" ? true : false,
      token: user?.token
    }
    put('/property/update', body)
      .then(json => {
        console.log('response from on click action', json);
        if (json.statusCode == 200) {
          if (!toast.isActive("property"))
            toast.success(json?.message, {
              toastId: "property"
            });
          getData(currentPage, perPage);
          setDeleteModal(false);
          props?.setCurrentData(null);
        } else {
          if (!toast.isActive("property"))
            toast.error(json?.error, {
              toastId: "property"
            });
        }
      })
      .catch(error => {
        console.error('error while deleting user', error);
      })
  }

  const handleUpdateProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData(proj);
    props.add(true);
  }

  const handleUpdateStatus = (item) => {
    console.log('updating proj', item);
    setCurrentData({ ...item, actionType: 'Status' });
    setDeleteModal(true);
  }

  const handleDeleteProj = (proj) => {
    console.log('updating proj', proj);
    props?.setCurrentData({ ...proj, actionType: 'Delete' });
    setDeleteModal(true);
  }

  const onDelete = () => {
    console.log('deleted', currentData);
    let body = {
      propertyId: currentData?._id,
      token: user?.token
    }
    let url = "/property/update";
    if (currentData?.actionType == 'Status') {
      body = { ...body, isActive: !currentData?.isActive };
      put(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData(currentPage, perPage);
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    } else {
      url = "/property/delete"
      body = { ...body };
      post(url, body)
        .then(json => {
          console.log('response from on click action', json);
          if (json.statusCode == 200) {
            toast.success(json?.message);
            getData(currentPage, perPage);
            setDeleteModal(false);
            props?.setCurrentData(null);
          } else {
            toast.error(json?.error);
          }
        })
        .catch(error => {
          console.error('error while deleting user', error);
        })
    }

  }

  const downloadZip = () => {
    setLoading(true);
    download("/property/download")
      .then(res => {
        console.log('response from download images', res);
        var blob = new Blob([res], { type: "application/octet-stream" });
        console.log('after blob', blob);
        setLoading(false);
        saveAs(blob, `store${new Date().getTime()}.zip`);
      })
      .catch(err => {
        console.log('error while downloading image', err);
      })
  }

  const handleAddReview = (data) => {
    setCurrentData(data);
    setIsReviewing(true);
  }

  const handleOnChange = (val, to, what) => {
    let obj = Object.assign([], reviewData);
    if (what == 'title')
      obj[to].title = val;
    else
      obj[to].desc = val;
    setReviewData(obj);
  }

  const handleValidReviewSubmit = () => {
    if (reviewData?.length) {
      setLoading(true);
      let body = {
        reviews: reviewData,
        propertyId: currentData?._id,
        token: user?.token
      }
      post("/review/add_auto_review", body)
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            toast.success(res?.message);
            setIsReviewing(false);
            setReviewData([{ title: "", desc: "" }]);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          setLoading(false);
          console.log('error while add auto review', err);
          toast.error("Something Went Wrong, Try Again!");
        })
    } else {
      toast.error("Add At least one review!");
    }
  }

  const addMore = () => {
    let obj = Object.assign([], reviewData);
    obj.push({ title: "", desc: "" });
    setReviewData(obj);
  }

  const removeReview = (index) => {
    let obj = Object.assign([], reviewData);
    obj.splice(index, 1);
    setReviewData(obj);
  }

  const handlePerRowsChange = (newPerPage, page) => {
    console.log('pagi', newPerPage, page);
    if (newPerPage) {
      setNewPerPage(newPerPage);
      getData(0, newPerPage);
    }
  }

  const handlePageChange = (page) => {
    console.log('paginat', page);
    getData(page - 1, newPerPage);
  }

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => onDelete()}
            onCloseClick={() => setDeleteModal(false)}
            data={currentData}
          />
          <Modal isOpen={isReviewing} toggle={() => setIsReviewing(false)} centered>
            <ModalBody>
              <h5>Add more Reviews on {currentData?.name}</h5>
              <AvForm onValidSubmit={handleValidReviewSubmit}>
                {reviewData?.map((item, index) => (
                  <div className="mt-2 form-control">
                    <Row style={{ flexDirection: 'row' }}>
                      <Col md={10}>
                        <p>Review {index + 1}</p>
                      </Col>
                      {index > 0 &&
                        <Col md={1}>
                          <button
                            type="button"
                            style={{ backgroundColor: "#d9604d", border: 'none', borderRadius: 5, marginLeft: 20 }}
                            onClick={() => removeReview(index)}
                          >
                            <i className="mdi mdi-delete" style={{ color: '#fff' }} />
                          </button>
                        </Col>
                      }
                    </Row>
                    {/* <AvField
                      type="text"
                      name={"title" + index}
                      placeholder="Enter review title"
                      value={item?.title}
                      onChange={(e) => handleOnChange(e.target.value, index, 'title')}
                      required
                    /> */}
                    <AvField
                      type="textarea"
                      className="mt-2"
                      rows={3}
                      name={"desc" + index}
                      placeholder="Enter review description"
                      value={item?.desc}
                      onChange={(e) => handleOnChange(e.target.value, index, 'desc')}
                      required
                    />
                    {reviewData?.length - 1 == index &&
                      <div style={{ width: '100%', marginTop: 10, justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}>
                        <button
                          type="button"
                          style={{ backgroundColor: "#d9604d", border: 'none', borderRadius: 5, marginLeft: 20, alignSelf: 'end', color: '#fff' }}
                          onClick={addMore}
                        >
                          <i className="mdi mdi-plus" style={{}} /> Add More
                        </button>
                      </div>
                    }
                  </div>
                ))}
                <Row>
                  <Col>
                    <div className="text-center mt-3">
                      <button
                        type="button"
                        className="btn btn-danger btn-lg me-2"
                        onClick={() => { setReviewData([{ title: "", desc: "" }]); setIsReviewing(false) }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success btn-lg me-2"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex" style={{ marginLeft: 'auto', marginBottom: 30 }}>
                  <div>
                    <CardTitle className="h4">{props.role}</CardTitle>
                    <CardSubtitle className="mb-3">
                      {role} you have added before, All the <code> users</code> will also be able see and <code>check</code> on these.
                    </CardSubtitle>
                  </div>
                  <div className="text-center" style={{ marginLeft: 'auto' }}>
                    {/* <Button style={{ backgroundColor: Utils.themeColor, marginRight: 10 }} onClick={() => downloadZip()}>
                        Zip Images
                      </Button> */}
                    {canAdd &&
                      <Button style={{ backgroundColor: Utils.themeColor }} onClick={() => props?.add(true)}>
                        Add
                      </Button>
                    }
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  paginationPerPage={50}
                  paginationRowsPerPageOptions={[50, 100, 150, 200]}
                  paginationServer
                  paginationTotalRows={totalPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  conditionalRowStyles={[{
                    when: row => row?.style,
                    style: row => ({ width: row?.style?.width }),
                  },
                  ]}
                  customStyles={{
                    headCells: {
                      style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: 15,
                        width: 0
                      },
                    },
                    cells: {
                      style: {
                        width: 0
                      }
                    }
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )
}

export default Table;
